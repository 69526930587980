h1, h2, h3, h4, h5, h6 {
  color: #19333f; }

body {
  color: #666; }

.r24wrap {
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

.r24red {
  color: #b4060b; }

.r24__required {
  vertical-align: middle;
  color: #666;
  font-size: 12px; }

.r24colors__single {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 8px; }

.r24colors--selected {
  box-shadow: 0 0 0 3px #fff, 0 0 8px 3px rgba(0, 0, 0, 0.5); }

.r24assign__subtitle {
  font-weight: normal;
  font-size: 16px;
  color: #19333f; }

.r24assign__title {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 25px; }

.r24assign__body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px; }

.r24assign__col {
  width: 33.3%;
  display: flex;
  flex-wrap: wrap; }

.r24assign--col3 {
  width: 66.6%; }

.r24assign .r24person {
  width: 46%; }

.r24person {
  border-radius: 3px;
  border: 1px solid #dddddd;
  margin-right: 20px;
  margin-bottom: 20px; }
  .r24person__head {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    border-radius: 3px 3px 0 0;
    color: #fff; }
  .r24person__name {
    font-weight: 500; }
  .r24person__pri {
    opacity: 0.6;
    font-size: 10px; }
  .r24person__body {
    padding: 16px;
    font-size: 12px; }
  .r24person .r24btn {
    display: block; }
  .r24person__title {
    font-weight: 600; }
  .r24person__desc {
    margin-bottom: 16px; }

.r24row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px; }

.r24col {
  padding-left: 15px;
  padding-right: 15px; }

.r24col-1 {
  width: 100% / 12; }

.r24col-2 {
  width: 16.66667%; }

.r24col-3 {
  width: 25%; }

.r24col-4 {
  width: 33.33333%; }

.r24col-5 {
  width: 41.66667%; }

.r24col-6 {
  width: 50%; }

.r24col-7 {
  width: 58.33333%; }

.r24col-8 {
  width: 66.66667%; }

.r24col-9 {
  width: 75%; }

.r24col-10 {
  width: 83.33333%; }

.r24col-11 {
  width: 91.66667%; }

.r24col-12 {
  width: 100%; }

.pa-t-1 {
  padding-top: .25rem !important; }

.pa-t-2 {
  padding-top: .5rem !important; }

.pa-t-3 {
  padding-top: 1rem !important; }

.pa-t-4 {
  padding-top: 1.5rem !important; }

.pa-t-5 {
  padding-top: 3rem !important; }

.pa-r-1 {
  padding-right: .25rem !important; }

.pa-r-2 {
  padding-right: .5rem !important; }

.pa-r-3 {
  padding-right: 1rem !important; }

.pa-r-4 {
  padding-right: 1.5rem !important; }

.pa-r-5 {
  padding-right: 3rem !important; }

.pa-l-1 {
  padding-left: .25rem !important; }

.pa-l-2 {
  padding-left: .5rem !important; }

.pa-l-3 {
  padding-left: 1rem !important; }

.pa-l-4 {
  padding-left: 1.5rem !important; }

.pa-l-5 {
  padding-left: 3rem !important; }

.pa-b-1 {
  padding-bottom: .25rem !important; }

.pa-b-2 {
  padding-bottom: .5rem !important; }

.pa-b-3 {
  padding-bottom: 1rem !important; }

.pa-b-4 {
  padding-bottom: 1.5rem !important; }

.pa-b-5 {
  padding-bottom: 3rem !important; }

.mar-t-1 {
  margin-top: .25rem !important; }

.mar-t-2 {
  margin-top: .5rem !important; }

.mar-t-3 {
  margin-top: 1rem !important; }

.mar-t-4 {
  margin-top: 1.5rem !important; }

.mar-t-5 {
  margin-top: 3rem !important; }

.mar-r-1 {
  margin-right: .25rem !important; }

.mar-r-2 {
  margin-right: .5rem !important; }

.mar-r-3 {
  margin-right: 1rem !important; }

.mar-r-4 {
  margin-right: 1.5rem !important; }

.mar-r-5 {
  margin-right: 3rem !important; }

.mar-l-1 {
  margin-left: .25rem !important; }

.mar-l-2 {
  margin-left: .5rem !important; }

.mar-l-3 {
  margin-left: 1rem !important; }

.mar-l-4 {
  margin-left: 1.5rem !important; }

.mar-l-5 {
  margin-left: 3rem !important; }

.mar-b-1 {
  margin-bottom: .25rem !important; }

.mar-b-2 {
  margin-bottom: .5rem !important; }

.mar-b-3 {
  margin-bottom: 1rem !important; }

.mar-b-4 {
  margin-bottom: 1.5rem !important; }

.mar-b-5 {
  margin-bottom: 3rem !important; }

.pa-0 {
  padding: 0 !important; }

.mar-0 {
  margin: 0 !important; }

.align-items-center {
  align-items: center; }

.r24-t-r {
  text-align: right; }

.r24-t-l {
  text-align: left; }

.r24-t-c {
  text-align: center; }

.r24-align-top {
  align-items: flex-start; }

.r24displ-none {
  display: none; }

.r24displ-block {
  display: block; }

.r24displ-flex {
  display: flex; }

.r24displ-inline {
  display: inline; }

.r24displ-inlineblock {
  display: inline-block; }

.r24phead {
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3); }
  .r24phead .r24wrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .r24phead__title {
    color: #19333f; }
  .r24phead__type {
    color: #666; }
  .r24phead--trans {
    background: transparent;
    box-shadow: none; }
  .r24phead--trans .r24phead__right {
    display: flex; }

.r24btn {
  display: inline-block;
  padding: 8px 25px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #666;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-image: linear-gradient(to bottom, #ffffff, #f3f3f3); }
  .r24btn--pri {
    background-image: linear-gradient(to bottom, #45a5ff, #4285f4);
    border-color: #3d86ff;
    color: #fff; }
  .r24btn--small {
    font-size: 11px;
    padding: 8px 17px; }
  .r24btn--wide {
    min-width: 155px; }
  .r24btn:hover {
    background-image: none;
    background: #19333f;
    color: #fff;
    border-color: transparent; }

.r24btns {
  display: flex; }
  .r24btns .r24btn {
    border-radius: 0; }
    .r24btns .r24btn:first-child {
      border-radius: 4px 0 0 4px; }
    .r24btns .r24btn:last-child {
      border-radius: 0 4px 4px 0; }
  .r24btns--active {
    background: #666;
    color: #fff; }

.r24collapse {
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }
  .r24collapse__head {
    padding: 4px 30px;
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center; }
  .r24collapse__panel {
    padding: 30px;
    display: none;
    position: relative;
    flex-wrap: wrap; }
  .r24collapse--sm .r24collapse__panel {
    padding-bottom: 70px; }
  .r24collapse__col {
    width: 50%; }
  .r24collapse__row {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .r24collapse__left {
    padding-right: 45px; }
  .r24collapse__right {
    padding-left: 45px; }
  .r24collapse__title {
    font-size: 18px;
    font-weight: 500; }
  .r24collapse__tlink, .r24collapse__plink {
    font-size: 13px;
    font-weight: 500;
    color: #4285f4;
    margin-left: 8px; }
  .r24collapse__plink {
    font-size: 11px; }
  .r24collapse__ptitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0; }
  .r24collapse__set {
    margin-bottom: 11px; }
  .r24collapse__arrow {
    font-size: 24px;
    margin-left: 15px;
    vertical-align: middle;
    position: relative;
    display: inline-block;
    transition: all 0.5s; }
  .r24collapse__more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #f9f9f9;
    border-top: 1px solid #ececec;
    text-align: center;
    color: #4285f4;
    font-size: 14px;
    padding: 8px; }
  .r24collapse--open .r24collapse__arrow {
    transform: rotateZ(180deg); }
  .r24collapse--open .r24collapse__panel {
    display: flex; }
  .r24collapse + .r24collapse {
    margin-top: 34px; }

.r24collapse__set .r24fg--vert {
  padding-right: 20px; }
  .r24collapse__set .r24fg--vert:last-child {
    padding-right: 0; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #999999; }

.r24fg {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .r24fg__label {
    font-size: 12px;
    width: 195px;
    color: #666; }
  .r24fg--top {
    align-self: flex-start;
    padding-top: 5px; }
  .r24fg__select {
    width: 58%;
    font-size: 12px; }
  .r24fg__input {
    width: 58%;
    font-size: 12px;
    height: 34px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;
    color: #333333;
    border: 1px solid #ececec;
    white-space: nowrap;
    line-height: 34px;
    text-decoration: none;
    border-radius: 5px;
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    background-image: none; }
  .r24fg__group {
    width: 58%;
    display: flex;
    align-items: center; }
    .r24fg__group + .r24fg__group {
      margin-top: 10px; }
  .r24fg__group:not(.r24fg--sep) .r24fg__input {
    border-radius: 0; }
    .r24fg__group:not(.r24fg--sep) .r24fg__input:first-child {
      border-radius: 5px 0 0 5px; }
    .r24fg__group:not(.r24fg--sep) .r24fg__input:last-child {
      border-radius: 0 5px 5px 0; }
  .r24fg--sep .r24fg__input {
    margin-right: 10px; }
    .r24fg--sep .r24fg__input:last-child {
      margin-right: 0; }
  .r24fg .select2-container .select2-choice .select2-arrow {
    border-left: 0; }
  .r24fg--textarea {
    height: auto; }
  .r24fg--disabled {
    background-color: #f9f9f9; }
  .r24fg__smalllab {
    font-size: 11px; }
  .r24fg--vert, .r24fg--wrap {
    flex-wrap: wrap; }
  .r24fg--vert .r24fg__label,
  .r24fg--vert .r24fg__input,
  .r24fg--vert .r24fg__select {
    width: 100%; }
  .r24fg--suffix .r24fg__input {
    width: 64.4%; }
  .r24fg--suffix .r24fg__input {
    width: 90%; }
  .r24fg--toggle {
    height: 34px; }
    .r24fg--toggle input {
      display: none; }
  .r24fg__lab-cont {
    margin-bottom: 0; }
  .r24fg--toggle .r24fg__bg {
    display: block;
    position: relative;
    width: 39px;
    height: 23px;
    border-radius: 55px;
    background: #666;
    transition: all 0.5s; }
  .r24fg--toggle .r24fg__toggler {
    width: 18px;
    height: 17px;
    border-radius: 50px;
    background: #fff;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: all 0.5s; }
  .r24fg--toggle input:checked ~ .r24fg__bg {
    background: #4285f4; }
  .r24fg--toggle input:checked ~ .r24fg__bg > .r24fg__toggler {
    left: 17px; }
  .r24fg--check .r24fg__lab-cont {
    width: 49%;
    margin-bottom: 10px; }
  .r24fg--check input {
    display: none; }
  .r24fg--check .r24fg__bg {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #dddddd;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    position: relative; }
  .r24fg--check input:checked ~ .r24fg__bg {
    background: #4285f4;
    border-color: #4285f4;
    color: #fff; }
  .r24fg--check .r24fg__toggler {
    position: absolute;
    top: -5px;
    left: 2px; }
  .r24fg--check .r24fg__inlabel {
    display: inline-block;
    vertical-align: text-bottom; }
  .r24fg--sp input {
    display: none; }
  .r24fg--sp .r24fg__toggler {
    display: block;
    padding: 6px;
    color: #666;
    border-right: 1px solid #ececec;
    font-weight: bold; }
  .r24fg--sp .r24fg__group {
    border: 1px solid #ececec; }
  .r24fg--sp .r24fg__lab-cont {
    width: 33.3%;
    text-align: center; }
  .r24fg--sp .r24fg__lab-cont:first-child .r24fg__toggler {
    border-radius: 3px 0 0 3px; }
  .r24fg--sp .r24fg__lab-cont:last-child .r24fg__toggler {
    border-radius: 0 3px 3px 0;
    border-right: 0; }
  .r24fg--sp input:checked ~ .r24fg__toggler {
    background: #4285f4;
    color: #fff; }
  .r24fg__addlink {
    margin-top: 13px;
    width: 100%;
    display: block;
    text-align: right;
    color: #4285f4;
    font-size: 11px; }
  .r24fg__radset input {
    display: none; }
  .r24fg__radset .r24fg__lab-cont {
    width: 100%;
    margin-bottom: 15px; }
  .r24fg__radset .r24fg__rbtn {
    display: inline-block;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    border-radius: 25px;
    border: 1px solid #dddddd;
    vertical-align: bottom; }
  .r24fg__radset input:checked ~ .r24fg__rbtn {
    border: 1px solid #4285f4;
    box-shadow: inset 0 0 0 2px #fff;
    background: #4285f4; }
  .r24fg__radset .r24fg__inlabel {
    display: inline-block; }

.r24pw {
  position: relative; }
  .r24pw__show {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: #4285f4;
    line-height: 34px;
    padding-right: 10px; }

.r24filters {
  display: flex;
  align-items: center;
  width: 100%; }
  .r24filters--right {
    margin-left: auto;
    margin-right: 0; }
  .r24filters__label {
    white-space: nowrap; }
  .r24filters__single {
    margin-bottom: 0;
    margin-right: 8px; }
  .r24filters .r24fg {
    width: 148px; }
  .r24filters__input {
    border: solid 1px #dddddd; }

.r24phead .r24filters {
  margin-top: 48px; }

.r24sec {
  margin-top: 27px; }
  .r24sec--fields {
    padding-bottom: 45px;
    margin-top: 48px; }
    .r24sec--fields > .r24wrap + .r24wrap {
      margin-top: 34px; }

.r24tech {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }
  .r24tech__head, .r24tech__row, .r24tech__foot {
    display: flex;
    justify-content: space-between; }
  .r24tech__col {
    min-width: 130px;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec; }
    .r24tech__col:first-child {
      flex-grow: 1;
      color: #333333; }
  .r24tech--hours .r24tech__col {
    min-width: 0; }
    .r24tech--hours .r24tech__col:first-child {
      min-width: 130px;
      max-width: 202px; }
  .r24tech__head {
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8; }
    .r24tech__head i {
      float: right;
      padding-top: 3px;
      font-size: 11px;
      color: #999999; }
  .r24tech__foot {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1); }
  .r24tech__head .r24tech__col,
  .r24tech__foot .r24tech__col {
    padding: 12px 15px 12px 20px; }
    .r24tech__head .r24tech__col:not(:first-child),
    .r24tech__foot .r24tech__col:not(:first-child) {
      color: #19333f; }
  .r24tech--hours .r24tech__head .r24tech__col,
  .r24tech--hours .r24tech__foot .r24tech__col {
    padding: 12px 10px; }
  .r24tech--current {
    font-weight: bold; }
  .r24tech--person {
    padding: 12px 15px 12px 20px; }
  .r24tech__color {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 4px; }
  .r24tech__name {
    font-size: 12px; }
  .r24tech__time {
    font-size: 11px;
    color: #999999; }
  .r24tech__body .r24tech__time,
  .r24tech__foot .r24tech__col:first-child .r24tech__time {
    float: right;
    padding-top: 2px; }
  .r24tech__shift {
    height: 55px;
    margin: 8px 5px;
    padding: 7px 12px;
    color: #fff;
    font-size: 11px;
    position: relative;
    cursor: pointer;
    transition: opacity 0.5s; }
  .r24tech__label {
    font-weight: normal;
    opacity: 0.5; }
  .r24tech__desc {
    position: absolute;
    right: 12px;
    bottom: 10px; }
  .r24tech--empty {
    background: #f1f1f1;
    transition: all 0.5s; }
    .r24tech--empty span {
      opacity: 0;
      text-align: center;
      font-weight: 500;
      display: block;
      line-height: 41px;
      color: #fff;
      transition: all 0.5s; }
    .r24tech--empty:hover {
      background: #4285f4; }
      .r24tech--empty:hover span {
        opacity: 1; }

.r24modal--medium {
  max-width: 528px; }

.r24modal.modal-dialog .modal-content {
  border: 0;
  border-radius: 3px !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }

.r24modal .close {
  float: left; }

.r24modal .modal-header {
  text-align: center; }

.r24modal--lp .modal-body {
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 43px;
  padding-right: 43px; }

.r24modal .r24fg:not(.r24fg--vert) .r24fg__label {
  max-width: 156px; }

.r24modal .r24fg__input, .r24modal__right {
  width: 64.4%; }

.r24modal .r24fg__group {
  width: 100%; }

.r24modal .r24fg__group .r24fg__input {
  width: 90%; }

.r24modal .r24pw__input {
  width: 100%; }

.r24modal .r24fg + .r24fg {
  margin-top: 24px; }

#tab-general {
  margin-left: -20px;
  margin-right: -20px;
  -webkit-font-smoothing: antialiased; }

.r24tech {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }
  .r24tech__head, .r24tech__row, .r24tech__foot {
    display: flex;
    justify-content: space-between; }
  .r24tech__col {
    min-width: 130px;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec; }
    .r24tech__col:first-child {
      flex-grow: 1;
      color: #333333; }
  .r24tech--hours .r24tech__col {
    min-width: 0; }
    .r24tech--hours .r24tech__col:first-child {
      min-width: 130px;
      max-width: 202px; }
  .r24tech__head {
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8; }
    .r24tech__head i {
      float: right;
      padding-top: 3px;
      font-size: 11px;
      color: #999999; }
  .r24tech__foot {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1); }
  .r24tech__head .r24tech__col,
  .r24tech__foot .r24tech__col {
    padding: 12px 15px 12px 20px; }
    .r24tech__head .r24tech__col:not(:first-child),
    .r24tech__foot .r24tech__col:not(:first-child) {
      color: #19333f; }
  .r24tech--hours .r24tech__head .r24tech__col,
  .r24tech--hours .r24tech__foot .r24tech__col {
    padding: 12px 10px; }
  .r24tech--current {
    font-weight: bold; }
  .r24tech--person {
    padding: 12px 15px 12px 20px; }
  .r24tech__color {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 4px; }
  .r24tech__name {
    font-size: 12px; }
  .r24tech__time {
    font-size: 11px;
    color: #999999; }
  .r24tech__body .r24tech__time,
  .r24tech__foot .r24tech__col:first-child .r24tech__time {
    float: right;
    padding-top: 2px; }
  .r24tech__shift {
    height: 55px;
    margin: 8px 5px;
    padding: 7px 12px;
    color: #fff;
    font-size: 11px;
    position: relative;
    cursor: pointer;
    transition: opacity 0.5s; }
  .r24tech__label {
    font-weight: normal;
    opacity: 0.5; }
  .r24tech__desc {
    position: absolute;
    right: 12px;
    bottom: 10px; }
  .r24tech--empty {
    background: #f1f1f1;
    transition: all 0.5s; }
    .r24tech--empty span {
      opacity: 0;
      text-align: center;
      font-weight: 500;
      display: block;
      line-height: 41px;
      color: #fff;
      transition: all 0.5s; }
    .r24tech--empty:hover {
      background: #4285f4; }
      .r24tech--empty:hover span {
        opacity: 1; }

.r24shift__name {
  font-size: 12px;
  color: #333333;
  font-weight: 500; }

.r24shift__smname {
  font-size: 11px;
  color: #666666; }

.r24shift__type {
  font-size: 12px;
  color: #666666;
  padding-left: 15px;
  line-height: 1;
  margin-top: -3px;
  margin-bottom: 12px; }

.r24shift__group {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.r24shift__single {
  padding: 15px;
  width: 29%;
  border-radius: 3px;
  border: solid 1px #dddddd; }
  .r24shift__single .r24btn {
    width: 100%;
    margin-top: 15px; }

.r24shift__smalldis {
  max-width: none;
  width: auto !important;
  padding-right: 10px; }

.tooltip {
  position: fixed !important;
  z-index: 9999 !important; }
  .tooltip .tooltip-inner {
    text-align: left;
    border-radius: 3px !important;
    background: #fff;
    color: #19333f;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4); }
  .tooltip.fade.in.bottom {
    opacity: 1 !important; }

.r24tech__col:hover {
  position: relative;
  z-index: 9999; }
